import React, { useEffect, useContext } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Box } from "theme-ui"
import Hero from "../components/hero"
import GalleryComp from "../components/galleryComp"
import Farmbox from "../components/farmbox"
import SeasonNews from "../components/seasonnews"
import HighGrounds from "../components/highgrounds"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { CustomEase } from "gsap/CustomEase"
import LangContext from "../components/Context/LangContext"

const IndexPage = ({ data }) => {
  const {
    farmTitleRu,
    farmImage,
    farmDescriptionRu,
    titleRu,
    subtitleRu,
    heroBackgroundImage,
    heroSeasonNews,
    seasonNews,
  } = data.allDatoCmsHomepage.nodes[0]

  console.log('heroSeasonNewsUniversal', heroSeasonNews)
  const heroSeasonNewsUniversal = {
    description: seasonNews.descriptionRu,
    title: seasonNews.titleRu,
    image: seasonNews.image,
  }


  const {
    seasonUpdatesRu,
    seasonDescriptionRu,
  } = data.allDatoCmsHomepage.nodes[0]

  const { nodes: seasonNewsArray = [] } = data.allDatoCmsSeasonNews
  const seasonNewsArrayMod = seasonNewsArray.map(x => {
    return {
      title: x.titleRu,
      description: x.descriptionRu,
      image: x.image,
    }
  })

  const { setPage, currentPage, setLang, currentLang } = useContext(LangContext)

  useEffect(() => {
    setPage("index")
  }, [])

  useEffect(() => {
    // var tlHero = gsap.timeline({ paused: true });
    // new SplitText(".splittextheading", {
    //   type: "lines",
    //   linesClass: `lineChild`
    // });
    // new SplitText(".splittextheading", {
    //   type: "lines",
    //   linesClass: "o-hidden"
    // });
    // tlHero.fromTo(`.hero__bg-cont`, {scale: 1.3}, {scale: 1.2,  duration: 4,})
    // tlHero.from(`.lineChild`, { opacity: 0, y: 30, ease: "expo", stagger: 0.1, duration:1.2,}, "<1");
    // tlHero.from('.splittextsubtitle', { opacity: 0, duration: 1, }, ">-0.5");
    // tlHero.from('.herobutton', {opacity: 0, stagger: 0.05, duration: 0.5}, "<0.5")
    // tlHero.from('.headerwrap', {y: '-100%', ease: "expo", duration: 2,}, "<0.5")
    // tlHero.from('.hero-zvezdica', {x: '-100%', rotate: -480, opacity: 0}, "<0.5")
    // tlHero.delay(5).play()
    // tlHero

    gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText, CustomEase)
    const customEase = CustomEase.create(
      "custom",
      "M0,0 C0.084,0.61 0.136,0.696 0.216,0.798 0.283,0.884 0.374,1 1,1 "
    )
    ScrollTrigger.create({
      trigger: ".farmBg",
      start: "top-=30% 50%",
      end: "bottom top",
      animation: gsap.to(".farmBg .gatsby-image-wrapper img", {
        scale: 1.1,
        y: "-5%",
      }),
      scrub: 1,
      // markers: true
    })
    ScrollTrigger.create({
      trigger: ".farmBg",
      start: "top-=30% 50%",
      end: "bottom top",
      animation: gsap.from(".farmBg", {
        opacity: 0,
        ease: "expo",
        duration: 1.5,
      }),
      // scrub: 0,
      // markers: true
    })
    // ScrollTrigger.create({
    //   trigger: '.hero__bg-cont',
    //   start: 'bottom bottom+=1',
    //   end: 'bottom top',
    //   animation: gsap.fromTo('.hero__bg-cont', { y: '0%', scale: 1.2 }, { y: '20%', scale: 1.2 }),
    //   scrub: 2,
    // })

    // Gallery
    ScrollTrigger.create({
      trigger: ".galleryTitle",
      start: "top bottom",
      end: "bottom top",
      animation: gsap.fromTo(
        ".galleryTitle",
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0 }
      ),
      scrub: false,
      toggleActions: "play none none reverse",
      // markers: true
    })
    ScrollTrigger.create({
      trigger: ".galleryTitle",
      start: "top bottom",
      end: "bottom top",
      animation: gsap.from(".slide-image", {
        x: "200",
        stagger: +0.1,
        ease: "expo",
        duration: 1.5,
      }),
      scrub: false,
      toggleActions: "play none none reset",
      // markers: true
    })
    ScrollTrigger.create({
      trigger: ".galleryTitle",
      start: "top bottom",
      end: "bottom top",
      animation: gsap.from(".galleryLogo", { rotate: 400 }),
      scrub: 1,
      toggleActions: "play none none reverse",
      // markers: true
    })
    ScrollTrigger.create({
      trigger: ".galleryLogo",
      start: "top bottom",
      end: "bottom top",
      animation: gsap.to(".galleryLogo svg", { rotate: 400 }),
      scrub: 3,
      toggleActions: "play none none reverse",
      // markers: true
    })

    gsap.utils.toArray(".splittext").forEach((text, index) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: text,
          start: "top 70%",
          end: "bottom top",
          toggleActions: "play none none none",
          // markers: true,
        },
      })
      new SplitText(text, {
        type: "lines",
        linesClass: `lineChild${index}`,
      })
      new SplitText(text, {
        type: "lines",
        linesClass: "o-hidden",
      })
      tl.from(`.lineChild${index}`, {
        opacity: 0,
        y: 30,
        ease: "expo",
        stagger: 0.05,
      })
    })

    // gsap.utils.toArray(".splittextheading").forEach((text, index) => {
    //   new SplitText(text, {
    //     type: "lines",
    //     linesClass: `lineChild${index}`
    //   });
    //   new SplitText(text, {
    //     type: "lines",
    //     linesClass: "o-hidden"
    //   });
    //   gsap.from(`.lineChild${index}`, { opacity: 0, y: 30, ease: "expo", stagger: 0.05, delay: 1 });
    // })

    //farmBox
    new SplitText("#farmBoxHeading", {
      type: "lines",
      linesClass: `farmBoxHeadingLine`,
    })
    new SplitText("#farmBoxHeading", {
      type: "lines",
      linesClass: "o-hidden",
    })
    new SplitText("#farmBoxDescription", {
      type: "lines",
      linesClass: `farmBoxDescription`,
    })
    new SplitText("#farmBoxDescription", {
      type: "lines",
      linesClass: "o-hidden",
    })

    const farmBoxHeadingTimeline = gsap
      .timeline()
      .from(".farmBoxHeadingLine", {
        opacity: 0,
        y: 30,
        ease: "expo",
        ease: customEase,
        duration: 0.5,
      })
      .from(".farmBoxDescription", {
        ease: customEase,
        opacity: 0,
        y: 30,
        stagger: 0.05,
      })
      .from("#farmBoxButton", {
        opacity: 0,
        duration: 2,
        ease: "expo",
        ease: customEase,
      })
      .fromTo(
        "#farmBoxCrown path",
        { drawSVG: "50% 50%", opacity: 0, ease: customEase },
        { duration: 1, drawSVG: "100%", ease: customEase, opacity: 1 },
        "<"
      )

    ScrollTrigger.create({
      trigger: ".farmBg",
      start: "bottom bottom",
      end: "bottom top",
      animation: farmBoxHeadingTimeline,
      // scrub: 0,
      // markers: true
    })

    // SeasonUpdates
    const seasonUpdatesTimeline = gsap
      .timeline()
      .from(".sesonupdates", {
        stagger: 0.25,
        opacity: 0,
        duration: 4,
        ease: "expo",
      })
      .from(
        ".sesonupdates .gatsby-image-wrapper img",
        { scale: 1.2, ease: "expo", duration: 10 },
        "<"
      )

    ScrollTrigger.create({
      trigger: ".sesonupdates",
      start: "top 80%",
      end: "bottom top",
      animation: seasonUpdatesTimeline,
      // markers: true,
      // scrub: 3,
      // toggleActions: "play none none reverse"
      // markers: true
    })

    // ScrollTrigger.refresh()
    return () => {
      // ScrollTrigger.refresh()
    }
  }, [])

  return (
    <Box sx={{}}>
      <SEO title="Home" />
      <Hero
        title={titleRu}
        subtitle={subtitleRu}
        heroBackgroundImage={heroBackgroundImage}
        heroSeasonNews={heroSeasonNewsUniversal}
      ></Hero>
      <Farmbox
        data={data}
        farmTitle={farmTitleRu}
        farmImage={farmImage}
        farmDescription={farmDescriptionRu}
      ></Farmbox>
      {/* <HighGrounds data={data}></HighGrounds> */}
      {/* <Box sx={{
        pt: 6,
        mb: 6,
      }}> */}
      {/* <GalleryComp data={data}></GalleryComp> */}
      {/* </Box> */}

      {/* <SeasonNews
        seasonUpdates={seasonUpdatesRu}
        seasonDescription={seasonDescriptionRu}
        seasonNewsArray={seasonNewsArrayMod}
      ></SeasonNews> */}
      <Box></Box>
    </Box>
  )
}

export default IndexPage

export const query = graphql`
  query HomepageRu {
    allDatoCmsHomepage {
      nodes {
        farmTitleRu
        farmDescriptionRu
        farmButtonTextRu
        farmImage {
          fluid {
            ...GatsbyDatoCmsFluid_tracedSVG
          }
        }
        
        heroBackgroundImage {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        seasonNews {
          image {
            fluid {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
          titleRu
          descriptionRu
        }
        heroSeasonNews {
          image {
            fluid {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
          titleRu
          descriptionRu
        }

        subtitleRu {
          benefit
        }
        titleRu
        galleryTitleEn
        galleryDescriptionEn
        seasonUpdatesRu
        seasonDescriptionRu
        homepageGallery {
          title
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }

    allDatoCmsSeasonNews {
      nodes {
        titleRu
        descriptionRu
        image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
